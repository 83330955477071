.container {
  padding: 0.3rem;
}

ul {
  list-style: none;
  padding: 0;
}
li {
  padding: 0.4rem;
}

.success {
  border-color: #35be54;
  box-shadow: 0px 0px 3px #35be54;
}

.card {
  padding: 1rem;
  box-shadow: 0px 0px 5px #a8a8a8;
  border-radius: 0.5rem;
  min-width: 350px;
}
.card_text {
  font-size: 1rem;
}
.disptext {
  padding: 0.3em;
  color: #6b6b6b;
  font-weight: 600;
  font-size: 0.9rem;
}
.codeBox {
  background-color: #1f1f1f;
  padding: 0.3rem;
  border-radius: 0.5rem;
  margin: 0.3rem;
  width: fit-content;
}
