.container {
  padding: 0.25rem;
  margin-block: 2rem;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
  gap: 10px;
  background-color: white;
  box-shadow: 0px 0px 10px #6ba4ef;
  border-radius: 1rem;
  padding: 1rem;
}
.item {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  text-decoration: none;
  box-shadow: 0px 0px 5px #cbcbcb;
  margin: 1rem;
  padding: 1rem;
  min-width: 15rem;
}
.item:hover .filler {
  width: 100%;
  transition: width 0.5s;
}

.middleWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  position: relative;
}
.itemFavIco {
  background-color: white;
  display: flex;
  padding: 0.25rem;
  border-radius: 0.5rem;
  z-index: 1;
}
.itemLinkText {
  font-size: 1.25rem;
  color: #141c4c;
  text-decoration: none;
  font-weight: 600;
  z-index: 1;
  margin-left: 1rem;
}

.itemFavIco img {
  height: 2rem;
}

.filler {
  height: 100%;
  width: 0%;
  background-color: #ced8ee;
  position: absolute;
  display: block;
  transform: translate(0%, -50%);
  top: 50%;
  left: 0;
  border-radius: 0.5rem;
}

