.container{
  min-height: 100vh;
  background: linear-gradient(45deg, #e7f0ff 10%, #ffefef 70%);
  padding: 1rem;
}

.header {
  color: #004a9f;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}
