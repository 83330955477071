.container {
    padding: 0.3rem;
  }
  .input_text {
    padding: 0.5rem;
    border-radius: 0.3rem;
    border: solid 1px var(--InputBorderColor);
    font-size: 1rem;
    outline: none;
  }
  .active:focus {
    border-color: var(--Active);
    box-shadow: 0px 0px 3px var(--Active);
  }
  