.container{
    padding: 0.25rem;
    margin-block: 2rem;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px;
  background-color: white;
  box-shadow: 0px 0px 10px #6ba4ef;
  border-radius: 1rem;
  padding: 1rem;
}
