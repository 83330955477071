
.container {
  height: 100vh;
  width: 100vw;
  background-color: #121212;
}

.centerflex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginbox {
  width: 80%;
  border-radius: 15px;
  box-shadow: 0px 0px 20px #000000;
  padding: 2rem;
  margin: 2rem;
  background-color: #142f48;
}
.inputbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titlebox h1 {
  color: #d3d1d1;
  text-align: center;
  font-size: var(--HeaderFont);
  font-weight: 100;
}
.wrapper {
  width: 70%;
  max-width: 500px;
  text-align: center;
}
.input {
  width: calc(100% - 2rem);
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
  border: solid 1px #121212;
  background-color: black;
  color: white;
  font-size: var(--InputFont);
}
.input:focus {
  outline: #8996fd;
  outline-style: solid;
}
.button {
  width: 30%;
  padding: 1rem;
  border-radius: 10px;
  background-color: #000000;
  color: #ffffff;
  font-size: var(--InputFont);
  border: solid 1px #142f48;
}

.button:hover {
  cursor: pointer;
  opacity: 0.6;
}


@media (410px<=width <= 550px) {
    html{
        font-size: 10px;
    }
  }
  @media (width < 410px) {
    html{
        font-size: 8px;
    }
  }